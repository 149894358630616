<template>
  <div class="ui grid container-3">
    <div class="ui mini modal ticket">

      <div class="header modalradiustop">
        Thank you for submitting a ticket.
      </div>
      <div class="image content">

        <div class="description">
          We'll process your request and get back to you shortly.
        </div>
      </div>
      <div class="actions modalradiusbottom">
        <a class="ui ok violet formodal" @click="helpCenterTicketClose">Ok</a>
      </div>
    </div>
    <div class="ticket-content">
      <h1><span>Submit a</span> Ticket</h1>
      <p>Not on our FAQs? Send us your concern and we’re happy to help.</p>
      <form class="ui form stackable">
        <div class="field">
          <div class="two fields">
            <div class="field">

              <div id="dropdown" class="ui selection dropdown">
                <input type="hidden" v-model="formHelpCenter.help_topic_id">

                <i class="dropdown icon"></i>
                <div class="default text">Subject of Concern <span>*</span></div>

                <div class="menu">
                  <div class="ui accordion">
                    <div @click="dropdownSelect" v-for="item in getHelpTopicSubject" :key="item.id">
                      <a class="title">
                        <i class="iconicon" v-if="item.helpTopicChildDtos.length != 0"></i>
                        <div class="item" :id="item.help_topic_id">
                          {{ item.name }}
                        </div>
                      </a>
                      <div class="content contentspecial" v-if="item.helpTopicChildDtos.length != 0">
                        <div @click="dropdownSelect" class="item itemsub" v-for="subitem in item.helpTopicChildDtos" :key="subitem.id">
                          <span class="subitem" :id="subitem.help_topic_id">- {{ subitem.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="errorHelpCenter">{{ helpCenterErrorMessageSubject }}</span>
            </div>

            <div class="field">
              <div class="numberSuffix">+63</div>
              <input type="number" class="inputNumber" @blur="blurNumber" @focus="focusNumber" v-model="formHelpCenter.contact_number"  v-on:keypress="isNumberOnly($event)" required="required">
              <label class="labelSpecial" for="name">Mobile Number</label>
              <span class="errorHelpCenter">{{ helpCenterErrorMessageContactNumber }}</span>
            </div>
          </div>
          <div class="two fields">
            <div class="field">
              <input type="text" v-model="formHelpCenter.name" required="required">
              <label for="name">Name </label>
              <span class="errorHelpCenter">{{ helpCenterErrorMessageName }}</span>
            </div>
            <div class="field">
              <input type="text" v-model="formHelpCenter.email" required="required">
              <label for="name">Email </label>
              <span class="errorHelpCenter">{{ helpCenterErrorMessageEmail }}</span>
            </div>
          </div>
          <div class="two fields">
            <div class="field">
              <textarea rows="2" cols="2" v-model="formHelpCenter.message" required="required"></textarea>
              <label for="name">Message </label>
              <span class="errorHelpCenter">{{ helpCenterErrorMessageMessage }}</span>
            </div>
            <div class="field">
              <input id="documentToUpload" type="file" ref="file" multiple required="required">
              <span class="errorHelpCenter">{{ helpCenterErrorMessageFileUpload }}</span>
            </div>
          </div>
        </div>
        <div class="recaptcha">
        <vue-recaptcha style="background-color: none; border: 0;"
          sitekey="6Ley8MUaAAAAANbM34FSrGOCNfyMSk1AMJhIEFat"
          ref="vueRecaptcha"
          @verify="onVerify"
          @expired="onExpired"
          size="normal"
          theme="clean"
         :loadRecaptchaScript="true">
        </vue-recaptcha>
        <span class="errorHelpCenter">{{ helpCenterErrorMessageCaptcha }}</span>
        </div>
        <div class="ui segment" v-if="sending">
          <div class="ui active inverted dimmer">
            <div class="ui text loader">Loading</div>
          </div>
          <p></p>
        </div>
        <a class="violet" @click="submitHelpCenter">Submit</a>
      </form>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import $ from 'jquery'

export default {
  name: 'content3',
  data () {
    return {
      sitekey: '6Ley8MUaAAAAANbM34FSrGOCNfyMSk1AMJhIEFat',
      sending: false,
      helpTicketModal: false,
      formHelpCenter: {
        help_topic_id: 0,
        contact_number: "",
        contactnumberEncrypted: "",
        name: "",
        email: "",
        emailEncrypted: "",
        message: "",
        document: ""
      },
      helpCenterErrorMessageSubject: "",
      helpCenterErrorMessageEmail: "",
      helpCenterErrorMessageContactNumber: "",
      helpCenterErrorMessageName: "",
      helpCenterErrorMessageMessage: "",
      helpCenterErrorMessageFileUpload: "Kindly attach supporting documents, if applicable.",
      helpCenterErrorMessageCaptcha: "",
      allValid: false,
      onVerifyCaptcha: false
    }
  },
  methods: {
    helpCenterTicketClose() {
      this.formHelpCenter = {}
      $('.ui.dropdown').dropdown('clear');
      var cols = document.getElementsByClassName('numberSuffix');
      cols[0].style.display = 'none';
      var colsLabel = document.getElementsByClassName('labelSpecial');
      colsLabel[0].style.display = 'inline';
    },

    dropdown () {
      $('.ui.dropdown').dropdown()
    },

    dropdownSelect: function(e) {
      this.formHelpCenter.help_topic_id = e.srcElement.id
    },

    recaptchaExpired () {
      this.$refs.vueRecaptcha.reset()
    },

    onSubmit () {
      this.$refs.invisibleRecaptcha.execute()
    },

    onVerify (response) {
      if(response){
        this.onVerifyCaptcha = true
      }
    },

    onExpired () {
      this.onVerifyCaptcha = false;
    },

    resetRecaptcha () {
      this.$refs.vueRecaptcha.reset() // Direct call reset method
    },
    submitHelpCenter(){

      this.allValid = true

      this.helpCenterErrorMessageSubject = "";
      this.helpCenterErrorMessageEmail = "";
      this.helpCenterErrorMessageContactNumber = "";
      this.helpCenterErrorMessageName = "";
      this.helpCenterErrorMessageMessage = "";
      this.helpCenterErrorMessageFileUpload = "Kindly attach supporting documents, if applicable.";
      this.helpCenterErrorMessageCaptcha = "";

      if (this.formHelpCenter.help_topic_id==0) {
        this.helpCenterErrorMessageSubject = "* Please input your suject of concern";
        this.allValid = false;
      }

      if (!this.formHelpCenter.name) {
        this.helpCenterErrorMessageName = "* Please input your complete name";
        this.allValid = false;
      }

      if (!this.formHelpCenter.message) {
        this.helpCenterErrorMessageMessage = "* Please enter a narration of your concern";
        this.allValid = false;
      }

      if (!this.formHelpCenter.contact_number) {
        this.helpCenterErrorMessageContactNumber = "* Please enter mobile number";
        this.allValid = false;
      } else if (this.formHelpCenter.contact_number.length != 10) {
        this.helpCenterErrorMessageContactNumber = "* Please enter a valid mobile number";
        this.allValid = false;
      }

      if (!this.formHelpCenter.email) {
        this.helpCenterErrorMessageEmail = "* Please enter email address";
        this.allValid = false;
      } else if (!this.validEmail(this.formHelpCenter.email)) {
        this.helpCenterErrorMessageEmail = "* Please enter a valid email address";
        this.allValid = false;
      }

      if(!this.validateFileUpload()){
        this.allValid = false;
      }

      if(!this.onVerifyCaptcha){
        this.helpCenterErrorMessageCaptcha = "* Please verify if you are human";
        this.allValid = false;
      }

      if(this.allValid) {
        this.sending = true
        this.formHelpCenter.document = this.$refs.file.files;
        this.formHelpCenter.contactnumberEncrypted = "63" + this.formHelpCenter.contact_number;
        this.formHelpCenter.emailEncrypted = this.formHelpCenter.email;

        this.$store
        .dispatch("createHelpCenter", this.formHelpCenter)
        .then(response => {

          if(response){
            this.sending = false
            window.$('.mini.modal.ticket')
              .modal({
                inverted: true
              })
              .modal('show')
            ;
            this.formHelpCenter = {}
            document.getElementById("documentToUpload").value = "";
            this.resetRecaptcha()
          }
        })
        .catch(error => {
          if(error){
            alert("Sorry we're encounter error upon submitting ticket.")
            this.sending = false
          }
        });
      }
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    isNumberOnly(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^\d+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    focusNumber() {
      var cols = document.getElementsByClassName('numberSuffix');
      cols[0].style.display = 'inline';
      var colsLabel = document.getElementsByClassName('labelSpecial');
      colsLabel[0].style.display = 'none';
    },

    blurNumber(e) {
      if(e.srcElement.value==""){
        var cols = document.getElementsByClassName('numberSuffix');
        cols[0].style.display = 'none';
        var colsLabel = document.getElementsByClassName('labelSpecial');
        colsLabel[0].style.display = 'inline';
      }
    },

    validateFileUpload() {
    //docs,xls,pdf,jpg,png,jpeg
    var _validFileExtensions = ["image/jpeg", "image/png", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/vnd.ms-excel"];

    if(this.$refs.file.files.length <= 5){
      var isvalidExt = false
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        // check ext
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if(this.$refs.file.files[i].type==sCurExtension){
            isvalidExt = true;
            break;
          }
        }

        if(!isvalidExt){
          this.helpCenterErrorMessageFileUpload = "Please upload a file with the following format (docs, xls, pdf, jpg, png)";
          return false;
        }

        // check file more than 5mb
        if(this.$refs.file.files[i].size > 5500000){
          this.helpCenterErrorMessageFileUpload = "Please upload not more than 5mb file";
          return false;
        }
      }
    } else {
      this.helpCenterErrorMessageFileUpload = "Please upload not more 5 files.";
      return false;
    }
      return true;
    }
  },

  mounted () {
    $('.ui.dropdown').dropdown()
    $('.ui.accordion').accordion()
  },

  created(){
    this.$store.dispatch("setHelpTopic");
  },

  computed: {
    getHelpTopicSubject() {
      return this.$store.getters.getHelpTopic;
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>

<style scoped lang='scss'>

.errorHelpCenter {
  color: red;
  font-size: .8em;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 0;
  display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number],
.inputNumber {
  border: 2px solid #9a9a9a !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  padding-left: 54px !important;
  position: relative !important;
  -moz-appearance: textfield;

  &:focus {
    border-color: #200f8c !important;
    padding-left: 54px !important;
  }
}

input[type=file] {
  border: 2px solid #9a9a9a !important;
  border-radius: 10px !important;
  font-size: 16px !important;

   &:focus {
    border-color: #200f8c !important;
  }
}

.numberSuffix {
  position: absolute;
  z-index: -1;
  margin-top: 13px;
  margin-left: 20px;
  font-size: 16px !important;
  display: none;
}

.labelSpecial {
  display: inline;
}

.subitem {

  font-family: 'Rubik', sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 10px 6px 22px !important;

  &.active.selected {
    font-weight: 400;
  }
}

.iconicon {
  position: absolute;
  transform: none;
  right: 0;
  transition: none;

  &:before {
    position: absolute;
    top: 12px;
    right: 16px;
    content: '';
    background-image: url(../../assets/plussmall.svg);
    background-repeat: no-repeat;
    width: 20px;
    display: block;
    height: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 16px;
    background-image: url(../../assets/plussmall.svg);
    background-repeat: no-repeat;
    width: 20px;
    display: block;
    height: 20px;
  }
}

.title.active {
  font-weight: 400;

  .iconicon {
    &:before {
      content: '';
      transition: none;
      -webkit-transition: opacity 3s ease-in-out;
      -moz-transition: opacity 3s ease-in-out;
      -ms-transition: opacity 3s ease-in-out;
      -o-transition: opacity 3s ease-in-out;
      background-image: url(../../assets/minussmall.svg);
      background-repeat: no-repeat;
      display: block;
    }

    &:after {
      content: '';
      transition: none;
      -webkit-transition: opacity 3s ease-in-out;
      -moz-transition: opacity 3s ease-in-out;
      -ms-transition: opacity 3s ease-in-out;
      -o-transition: opacity 3s ease-in-out;
      background-image: url(../../assets/minussmall.svg);
      background-repeat: no-repeat;
      display: block;
    }
  }
}

.text {
  span {
    padding: 0px !important;
  }
}

.modalradiustop {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom: none !important;
  color: #200f8c !important;
  text-align: center;
}

.modalradiusbottom {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top: none !important;
  background: #ffffff !important;
}

.ui.mini.modal.ticket {
  border-radius: 20px;
}

.ui.modal>.content>.description {
  text-align: center;
  max-width: 100%;
}

.violet.formodal {
  padding-top: 10px;
  margin-left: 114px;
  width: 100px
}

.ui.form .ui.selection.dropdown .menu .item.itemsub {
  padding: 6px !important;
}

.content.contentspecial {
  padding-top: 0 !important;
}

.ui.form input[type=file] {
  height: 68px;
}
</style>
